// Class definition

var controls = {
    leftArrow: '<i class="fal fa-angle-left" style="font-size: 1.25rem"></i>',
    rightArrow: '<i class="fal fa-angle-right" style="font-size: 1.25rem"></i>'
}

var runDatePicker = function () {

    // enable clear button 
    $('#datepicker-3').datepicker(
        {
            clearBtn: true,
            todayHighlight: true,
            templates: controls
        });
}

$(document).ready(function () {
    runDatePicker();
});
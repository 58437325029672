import './Vendor/intlTelInput';
import intlTelInput from './Vendor/intlTelInput';

$(function() {
    var defaultPhoneCountry = 'gb';

    var phoneGlobalSettings = {
        // utilsScript: "/assets/js/intlTelInputUtils.js",
        initialCountry: defaultPhoneCountry,
        nationalMode: false,
        formatOnDisplay: true,
        autoHideDialCode: false,
        customPlaceholder: () => 'Phone',
        preferredCountries: ['gb', 'cu']
    };
    
    // intlTelInput(phoneGlobalSettings);
    // $('.phone-control').intlTelInput(phoneGlobalSettings);
    var control = document.querySelectorAll('.phone-control');
    if (control) {
        control.forEach((element) => {
            intlTelInput(element, phoneGlobalSettings);
        })
    }
});